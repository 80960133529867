import React from 'react';
import ReCAPTCHA from 'reaptcha';

const SITE_KEY = '6LcrG98UAAAAAClv_aNZN5NWaN8KMRL-_Tk3w7zA';

const Recaptcha = ({recaptchaRef, onVerify}) => {

    return (
        <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={SITE_KEY}
            hl="de"
            size="invisible"
            onVerify={onVerify}
            className="hidden"
        />
    );
};

export default Recaptcha;