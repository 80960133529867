import axios from 'axios';
import {useState} from 'react';

const ROOT_PATH = typeof window === `undefined` || window.location.origin.indexOf('localhost') === -1 ? process.env.SITE_URL : window.location.origin;
const API_PATH = `${ROOT_PATH}/api/contact/sendMail.php`;

export const useCustomForm = (form) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [sendStatus, setSendStatus] = useState('');
    const {getFieldsError, getFieldsValue, validateFields, resetFields, getFieldError} = form;

    const handleValidation = () => {
        setSendStatus('');
        setIsFormValid(!hasErrors(getFieldsError()) && !hasInvalidFields(getFieldsValue()));
    };

    const handleSendForm = (response) => {
        validateFields()
            .then((values) => {
            if (response !== null) {
                values.token = response;
                return axios({
                    method: 'post',
                    url: `${API_PATH}`,
                    headers: {'content-type': 'application/json; charset=UTF-8'},
                    data: values,
                })
                    .then((result) => {
                        setIsFormValid(false);
                        if (result.data.error) {
                            setSendStatus('error');
                        } else {
                            setSendStatus('success');
                            resetFields();
                        }
                    })
                    .catch(error => console.error(error))
            }
        });
    };

    const hasErrors = (fieldsError) => {
        return fieldsError.filter(({ errors }) => errors.length).length;
    };

    const hasInvalidFields = (fieldsValues) => {
        return Object.keys(fieldsValues).some(field => fieldsValues[field] === undefined);
    };

    return {handleSendForm, handleValidation, isFormValid, sendStatus, getFieldError}
};
